export default {
  en: {
    title: "",
  },
  pl: {
    title: "",
    button: "Weź udział",
    urlButton: "https://evenea.pl/pl/wydarzenie/hack4change2025",
    menu: [
      {
        name: "Idea",
        link: "/#beTheChange",
      },
      {
        name: "Obszary tematyczne",
        link: "/#obszary-tematyczne",
      },
      {
        name: "Dla kogo?",
        link: "/#forWho",
      },
      // {
      //   name: "Propozycje zadań",
      //   link: "/#propozycje",
      // },
      {
        name: "Warsztaty",
        link: "/workshops",
      },
      {
        name: "Mentorzy",
        link: "/#mentorzy",
      },
      {
        name: "Korzyści",
        link: "/#korzysci",
      },
      // {
      //   name: "Harmonogram",
      //   link: "/#harmonogram",
      // },
      {
        name: "FAQ",
        link: "/#faq",
      },

      {
        name: "Edycje ubiegłe",
        subMenu: [
          {
            name: "2024",
            link: "/summary-2024",
          },
          {
            name: "2023",
            link: "/summary-2023",
          },
          {
            name: "2022",
            link: "/summary-2022",
          },
          {
            name: "2020",
            link: "/summary-2020",
          },
        ],
      },
      {
        name: "Współpraca",
        link: "/#idea",
      },
    ],
  },
}
