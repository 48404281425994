import React from "react"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import Text from "../Text"
import Gadget from "../Gadget"
import Logo from "../Logo"
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"

import * as s from "./style.module.css"
import { graphql } from "gatsby"

function Idea({ lang }) {
  const t = useLabels(labels, lang)

  return (
    <>
      <div className={s.footerMenu}>
        <Container>
          <Row className="mt-4" xs={1} md={2} lg={4}>
            <Col lg={4}>
              <Logo isFooter />
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{ __html: t.section.text }}
              />
              <div className="mt-5 mb-3">
                <a
                  className={s.contactLink}
                  href={`mailto: ${t.section.email}`}
                >
                  <strong>{t.section.email}</strong>
                </a>
              </div>
              <div>
                <a className={s.contactLink} href={`tel: ${t.section.phone}`} target="_blank">
                  <strong>{t.section.phone}</strong>
                </a>
              </div>
            </Col>
            <Col className="mt-4" lg={2}>
              <h6 className={s.labelColumn}>{t.menu.title}</h6>
              <ul className="mt-4 nav flex-column">
                {t.menu.elements.map(el => (
                  <li className="nav-item">
                    <a className="nav-link" href={el.link}>
                      {el.name}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col className="mt-4" lg={2}>
              <h6 className={s.labelColumn}>{t.info.title}</h6>
              <ul className="mt-4 nav flex-column">
                {t.info.elements.map(el => (
                  <li className="nav-item">
                    <a className="nav-link" href={el.link} target="_blank">
                      {el.name}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col className="mt-4" lg={4}>
              <h6 className={s.labelColumn}>{t.socials.title}</h6>
              <div className={s.socialsWrapper}>
                {t.socials.elements.map(element => (
                  <div className={s.social}>
                    <a className="d-block" href={element.url} target="_blank">
                      <img src={element.icon} />
                    </a>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={s.footer}>
        <Container className="d-flex h-100 align-items-center ">
          {t.footer}
        </Container>
      </div>
    </>
  )
}

export default Idea
