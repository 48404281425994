import React from "react"
import * as s from "./style.module.css"

export default function index({ isFooter }) {
  return (
    <div className={isFooter ? s.footerRoot : s.root}>
      <img src={require("./images/logo.svg").default} />
    </div>
  )
}
