import React from "react"

import * as s from "./style.module.css"

export default function index({ title, text, children }) {
  return (
    <div className={s.root}>
      <h3 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
      <img className={s.line} src={require("./icons/line.svg").default} />
      <div className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
      {children}
    </div>
  )
}
