import React from 'react'
import * as s from './style.module.css';

export default function Gadget({ version }) {
  switch (version) {
    case 'green':
      return (
        <div className={s.root2}>
          <div className={s.ellipse3}>
          </div>
          <div className={s.ellipse4}>
          </div>
        </div>
      )
    default:
      return (
        <div className={s.root}>
          <div className={s.ellipse}>
          </div>
          <div className={s.ellipse2}>
          </div>
        </div>
      )
  }

}
