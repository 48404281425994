export default {
  en: {
    title: "",
  },
  pl: {
    section: {
      text: `
        <strong>Hack4Change</strong>
        <br/>
        <br/>
        Marcin Młyński<br/>
        Fundacja CODE:ME
      `,
      email: "kontakt@hack4change.tech",
      phone: "+48 732 012 597",
    },
    socials: {
      title: "Social Media",
      elements: [
        {
          icon: require("./images/fb.svg").default,
          url: "https://www.facebook.com/Hack4changeTECH",
        },
        {
          icon: require("./images/insta.svg").default,
          url: "https://www.instagram.com/hack_4_change/",
        },
        {
          icon: require("./images/ln.svg").default,
          url: "https://www.linkedin.com/in/hack4change-tech-9703651ab/",
        },
      ],
    },
    menu: {
      title: "Menu",
      elements: [
        {
          name: "Idea Hack4Change",
          link: "#beTheChange",
        },
        {
          name: "Harmonogram",
          link: "#harmonogram",
        },
        {
          name: "Eksperci",
          link: "/mentors",
        },
        {
          name: "Korzyści",
          link: "#korzysci",
        },
        {
          name: "Warsztaty",
          link: "/workshops",
        },
        // {
        //   name: "3 wyzwania",
        //   link: "/topics",
        // },
        {
          name: "FAQ",
          link: "/#faq",
        },
      ],
    },
    info: {
      title: "Informacje",
      elements: [
        {
          name: "Regulamin",
          link: "https://drive.google.com/file/d/1mJOacWkWbX-dGJkz0XDkBpGnXBr_HdDm/view?usp=sharing",
        },
        {
          name: "Zgoda rodzica",
          link: "https://drive.google.com/file/d/1mMpdL1FSkazokBK0NEifEBTrwiq2Jgk2/view?usp=drive_link",
        },
        {
          name: "Polityka prywatności",
          link: "/Polityka_prywatności_hack4change_2023.pdf",
        },
      ],
    },
    footer: "2024 @ Hack4Change. Wszystkie prawa zastrzeżone.",
  },
}
