/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import './layout.scss'
import "./layout.css"
import {
  Container,
} from 'reactstrap';

import Header from "../Header"
import Footer from "../Footer"


const Layout = ({ lang = 'pl', children }) => {
  return (
    <>
      <Header lang={lang} />
      <div className={"wrapper"}>
        {children}
      </div>
      <Footer />
      <link href="https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,800|Montserrat:800&display=swap" rel="stylesheet" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
