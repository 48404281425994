import React, { useState } from "react"
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap"
import * as s from "./style.module.css"
import logo from '../Logo/images/hack4change-final@3x.png'
import logoWhite from '../Logo/images/hack4change-final-white@3x.png'

const AccessibilityContainer = () => {
  const [biggerFont, setBiggerFont] = useState(false)
  const [background, setBackground] = useState(false)
  if (typeof document != "undefined") {
    biggerFont
      ? document.documentElement.style.setProperty("font-size", "19px")
      : document.documentElement.style.setProperty("font-size", "15px")

    if (background) {
      document.documentElement.style.setProperty("--white", "#171717")
      document.documentElement.style.setProperty("--buttonWhite", "yellow")
      document.documentElement.style.setProperty("--white-four", "black")
      document.documentElement.style.setProperty("--black-two", "yellow")
      document.documentElement.style.setProperty("--text-black", "yellow")
      document.documentElement.style.setProperty("--h2-background", "black")
      document.documentElement.style.setProperty("--invert-color", "invert(1)")
      document.documentElement.style.setProperty("--logo", `url(${logoWhite})`)
      document.documentElement.style.setProperty(
        "--sickly-yellow-button",
        "yellow"
      )
      if (document.getElementById("propozycje"))
        document.getElementById("propozycje").style.color = "yellow";
    } else {
      document.documentElement.style.setProperty("--white", "#ffffff")
      document.documentElement.style.setProperty("--white-four", "#f5f5f5")
      document.documentElement.style.setProperty("--buttonWhite", "#ffffff")
      document.documentElement.style.setProperty("--black-two", "#171717")
      document.documentElement.style.setProperty("--h2-background", "transparent")
      document.documentElement.style.setProperty("--text-black", "#212529")
      document.documentElement.style.setProperty("--invert-color", "invert(0)")
      document.documentElement.style.setProperty("--logo", `url(${logo})`)
      document.documentElement.style.setProperty(
        "--sickly-yellow-button",
        "#c4e230"
      )
      if (document.getElementById("propozycje"))
        document.getElementById("propozycje").style.color = "black";
    }
  }

  return (
    <>
      <div className={s.accessibilitySection}>
        <div onClick={() => setBiggerFont(!biggerFont)}>
          {biggerFont ? (
            <>
              <img
                className={s.icons}
                alt="ikona rozmiaru czcionki"
                src={require("./icons/fontSize.svg").default}
              />
              <span className={s.textSpan}>
                Rozmiar tekstu Aa-
              </span>
            </>
          ) : (
            <>
              <img
                className={s.icons}
                alt="ikona rozmiaru czcionki"
                src={require("./icons/fontSize.svg").default}
              />
              <span className={s.textSpan}>
                Rozmiar tekstu Aa+
              </span>
            </>
          )}
        </div>
        <div onClick={() => setBackground(!background)}>
          <img
            className={s.icons}
            alt="ikona rozmiaru czcionki"
            src={require("./icons/contrast.svg").default}
          />
          <span className={s.textSpan}>
            Kontrast
          </span>
        </div>
      </div>
    </>
  )
}

export default AccessibilityContainer
