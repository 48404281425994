import React, { useState } from "react"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Button,
} from "reactstrap"
import Logo from "../Logo"
import "./style.css"
import AccessibilityContainer from "../AccessibilityContainer"

function Header({ lang }) {
  const t = useLabels(labels, lang)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  return (
    <div className="mb-5">
      <div className="headerWrapper">
        <Navbar fixed="top" dark expand="xl">
          <NavbarBrand href="/">
            <Logo />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <AccessibilityContainer />
              {t.menu.map(({ name, link, subMenu }) => {
                if (name === "Edycje ubiegłe") {
                  return (
                    <NavItem>
                      <div className="dropdown show">
                        <NavLink
                          lassName="dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={close}
                        >
                          Edycje ubiegłe 🡫
                        </NavLink>

                        <div
                          className="dropdown-menu nav-item-bg"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {subMenu.map(menuItem => {
                            return (
                              <NavLink onClick={close} href={menuItem.link}>
                                {menuItem.name}
                              </NavLink>
                            )
                          })}
                        </div>
                      </div>
                    </NavItem>
                  )
                } else {
                  return (
                    <NavItem>
                      <NavLink onClick={close} href={link}>
                        {name}
                      </NavLink>
                    </NavItem>
                  )
                }
              })}
            </Nav>
            <a href={t.urlButton} target="_blank">
              <Button
                color="primary"
                dangerouslySetInnerHTML={{ __html: t.button }}
              />
            </a>
          </Collapse>
        </Navbar>
      </div>
    </div>
  )
}

export default Header
